import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseIcon from './close';
import {Button , Button2 , FitFinderPans} from "./components/index";
import {Measurement} from './components';
import { language as Language_ } from './language';
import {useSuperfan} from "@pikabobalex/superfan-module"

let measureMents = {
  height : {min : 100, max : 200},
  waist : {min : 58, max : 120},
  hip : {min :85, max : 130},
}

const convertToInches = (value) => {
  return Math.round((parseFloat(value) / 2.54).toFixed(2));
};

function Index({
  setOnToggle,
  onToggle,
  _language,
  setLanguage
}) {
  const [sliderValues, setSliderValues] = useState({
    height: measureMents['height'].max /2 + measureMents['height'].min / 2,
    waist: measureMents['waist'].max / 2 + measureMents['waist'].min / 2,
    hip: measureMents['hip'].max / 2 + measureMents['hip'].min / 2,
  });
  const {
    recordEvent,
    recordGameData,
    uuid
  } = useSuperfan();

  const [unit, setUnit] = useState('cm');
  const [language_ , _setLanguage] = useState(null);
  const langaugeSellected = useMemo(() => {
    let seachParams = new URLSearchParams(window.location.search);
    let language = language_ || seachParams.get('language');
    let product = seachParams.get('product');
    recordGameData("language", language || "en")
    if(language === "en"){
      setLanguage(Language_["en"])
      return "EN"
    }
    else if(language === "hk"){
      setLanguage(Language_["hk"])
      return "HK"
    }
    else if(language === "tw"){
      setLanguage(Language_["tw"])
      return "TW"
    }
    else if(language === "kr"){
      setLanguage(Language_["kr"])
      return "KR"
    }
    else if(language === "jp"){
      setLanguage(Language_["jp"])
      return "JP"
    }
    else{
      setLanguage(Language_["en"])
      return "EN"
    }
  }, [language_]);

  let size = useMemo(() => {
    let seachParams = new URLSearchParams(window.location.search);
    let type = seachParams.get('type');
    let product = seachParams.get('product');
    product = product ? product : "90s Straight";
    type = type ? type : "asiamen";
    
    let size = FitFinderPans( sliderValues.waist, sliderValues.hip , type , product);
    return size;
  }, [sliderValues]);


  const handleSliderChange = (event, slider) => {
    setSliderValues({
      ...sliderValues,
      [slider]: event.target.value
    });  
  };

  const handleSliderChangeIN = (event, slider) => {
    setSliderValues({
      ...sliderValues,
      [slider]: Math.round(event.target.value * 2.54)
    });  
  };


  const toggleUnit = (targetUnit) => {
    setUnit(targetUnit);
  };


  const renderValue = (value , type = null) => {
    if(type === "height"){
      return value;
    }
    else{
      return unit === 'cm' ? value : convertToInches(value);
    }
  };

  const [onToggle2 , setOnToggle2] = useState(false)


  const [ measurement , setMeasurement ] = useState({
    height : {min : 100, max : 200},
    waist : {min : 58, max : 120},
    hip : {min :85, max : 130},
  });

  useEffect(() => {
    let seachParams = new URLSearchParams(window.location.search);
    let type = seachParams.get('type');
    type = type ? type : "asiamen";

    setMeasurement({
      height : {min : 100 , max : 200},
      waist : {min : Measurement[type].min.waist , max : Measurement[type].max.waist},
      hip : {min : Measurement[type].min.hip , max : Measurement[type].max.hip},
    });

    setSliderValues({
      height: 150,
      waist: Measurement[type].max.waist / 2 + Measurement[type].min.waist / 2,
      hip: Measurement[type].max.hip / 2 + Measurement[type].min.hip / 2,
    });
  }, []);



  return (
      <div className="side_container" style={{
        position : "relative",
      }}>

        <div className='closeIcon'
          onClick={()=>{
            // send massage to parent
            recordEvent("close")
            window.parent.postMessage("close","*")
          }}
          style={{
            opacity : !onToggle ? 1 : 0,
            transition : !onToggle ? "opacity 0.5s ease 0.3s" : "0.3s",
            pointerEvents : !onToggle ? "all" : "none",
          }}
        >
          <div className='leftIcon'/>
          <div className='rightIcon'/>
        </div>

        <div className='languagContainer'
          onClick={()=>{
            // send massage to parent
            recordEvent("language")
          }}
          style={{
            opacity : !onToggle ? 1 : 0,
            transition : !onToggle ? "opacity 0.5s ease 0.3s" : "0.3s",
            pointerEvents : "none",
          }}
        >
          <h2>{langaugeSellected}</h2>
        </div>


        <div className='block' style={{
          height : _language.type === "kr" || _language.type === "jp"? "6.5%" : "10%"
        }}/>

          <h1
          >{_language.page1.title}</h1>

          <select
            style={{
              position : "absolute",
              top : "2%",
              left : "2%",
              zIndex : "1000",
              width : "15%",
              height : "5%",
              opacity : 0
            }}

            onChange={(e) => {
              recordGameData("language", e.target.value)
              _setLanguage(e.target.value)
            }}
          >
            {
              Object.keys(Language_).map((item, key) => {
                return <option key={key} value={item}>{item.toUpperCase()}</option>
              })
            }
          </select>

      <div className='block' style={{
          height : "2%"
        }}/>

          <p
            style={{
              opacity : onToggle2 ? 0 : 1,
            }}
          >
          {_language.page1.content.split("<br/>").map((item, key) => {
              return <div key = {key}>{item}<br/></div>
            })} <br />
            {/* <b>{_language.page1.product}</b> {Product.split("<br/>").map((item, key) => {
              return <span key={key} style={{
                fontSize : "inherit",
                fontFamily : "inherit",
              }}>{item}<br/></span>
            })} */}
          </p>

          <div className='block' style={{
            height : "5%"
          }}/>

          <div
            style={{
              height : "35%"
            }}
          >
            {
              !onToggle2?
              <C1
                handleSliderChange={handleSliderChange}
                handleSliderChangeIN={handleSliderChangeIN}
                sliderValues={sliderValues}
                renderValue={renderValue}
                unit={unit}
                toggleUnit={toggleUnit}
                _language = {_language}
                measurement = {measurement}
              />
              :
              <C2 size = {size} _language = {_language} sliderValues = {sliderValues} />
            }

          </div>
          
          <div className='block' style={{
            height : _language.type === "en" ? "8%" : "6%"
          }}/>

          <div className='block_mobile' style={{
                height : "0.5rem"
          }}/>


          <h4 style={{
            textDecoration : "underline",
          }} 
            onClick={()=>{
              setOnToggle(!onToggle)
              recordEvent("howtomeasure")
            }}
           >
            {_language.page1.howtomeasure}
          </h4>

          <div className='block' style={{
            height : _language.type === "en" ? "8%" :"6%"
          }}/>

          <Button
            id = {onToggle2 ? "tryagain" : "findmysize"}
            onClick = {()=>{
              setOnToggle(false)
              setOnToggle2(!onToggle2)
              if(!onToggle2){
                recordGameData("bodyshape" , {
                  height : sliderValues.height,
                  waist : sliderValues.waist,
                  hip : sliderValues.hip
                })

                let seachParams = new URLSearchParams(window.location.search);
                let product = seachParams.get('product');
                let language = seachParams.get('language');
                product = product ? product : "null";
                recordGameData("product", product || "null")
                recordGameData("language", language || "en")            
              }
            }}
          >{!onToggle2 ? _language.page1.findmysize :  _language.page1.tryagain}</Button>
      </div>
  );
}

const Slider = ({
  handleSliderChange,
  handleSliderChangeIN,
  sliderValues,
  renderValue,
  unit,
  type,
  content,
  _language,
  measurement
})=>{
  const valueRef = useRef(null);

  useMemo(() => {
    if(valueRef.current === null) return;
    valueRef.current.value = renderValue(sliderValues[type] , type);
  }, [unit]);
  return (
    <div
      style={{
        width : "80%",
        height: "fit-content",
        margin : "auto",
      }}
    >
        <label htmlFor="slider1">{content} <span>({type === "height" ? _language.cm : unit === "cm" ? _language.cm : _language.in})</span></label>
        <div className='block_mobile' style={{
              height : "0.5rem"
        }}/>

        <div className="slider_container"
          style={{
            display : "flex",
            alignItems : "center",
          }}
        >
          <div
            style={{
              width : "100%",
              display : "flex",
            }}
          >
            <input
              type="range"
              min={measurement[type].min}
              max={measurement[type].max}
              value={sliderValues[type]}
              onChange={(e) => {
                valueRef.current.value = renderValue(e.target.value , type);
                handleSliderChange(e, type);
              }}
              className="slider"
              style={{
                width : "100%",
              }}
            />
          </div>

          <div
            style={{
              marginLeft : "5%",
              width : "20%",
              display : "flex",
            }}
          >
            <input
              type='number'
              className='number_input'
              ref={valueRef}
              style={{
                borderRadius : "0",
                border : "1px solid #000",
                pointerEvents : "none",
              }}
              defaultValue={renderValue(sliderValues[type] , type)}
              onFocus={(e) => {
                e.target.value = '';
              }}
              onBlur={(e) => {
                let _min = unit === 'cm' ? measureMents[type].min : convertToInches(measureMents[type].min);
                let _max = unit === 'cm' ? measureMents[type].max : convertToInches(measureMents[type].max);
                if(_min > parseInt(e.target.value) || _max <= parseInt(e.target.value)){
                  valueRef.current.classList.add('shake');
                  setTimeout(() => {
                    valueRef.current.classList.remove('shake');
                  }, 1000);
                }
              
                e.target.value = renderValue(sliderValues[type] , type);
              }}
              onChange={(e) => {
                if(unit === 'cm'){
                  handleSliderChange(e, type);
                }
                else{
                  handleSliderChangeIN(e, type);
                }
              }}
            />
          </div>
        </div>
    </div>
  )
}

const C1 = ({
  handleSliderChange,
  handleSliderChangeIN,
  sliderValues,
  renderValue,
  toggleUnit,
  unit,
  _language,
  measurement
})=>{
  const product = useMemo(() => {
    let seachParams = new URLSearchParams(window.location.search);
    let product = seachParams.get('product');
    product = product ? product : "90s Straight";
    return product;
  }, []);

  const {
    recordEvent
  } = useSuperfan();


  return (
    <>
      <div className="unit_btn_container">
              <div className="unit_btn"
                style={{
                  border : "1px solid #000",
                }}
              >
                <button
                  style={{
                    width : "clamp(2.25rem, 4.5svh, 4rem)",
                    padding : "clamp(0.15rem, 0.3svh, 0.3rem) 0",
                    paddingTop : "clamp(0.36rem, 0.7svh, 0.68rem)",
                  }}
                  className={unit === 'cm' ? 'selected' : '' } 
                  onClick={() => {
                    toggleUnit('cm')
                    recordEvent("cm")
                  }}
                  >
                    <h4
                      style={{
                        textTransform : "uppercase",
                      }}
                    >
                      {_language.page1.cm}
                    </h4>
                </button>

                <button 
                    className={unit === 'in' ? 'selected' : ''} 
                    onClick={() => {
                      toggleUnit('in')
                      recordEvent("in")
                    }}
                    style={{
                      width : "clamp(2.25rem, 4.5svh, 4rem)",
                      padding : "clamp(0.15rem, 0.3svh, 0.3rem) 0",
                      paddingTop : "clamp(0.36rem, 0.7svh, 0.68rem)",
                    }}
                   
                  >
                    <h4
                        style={{
                          textTransform : "uppercase",
                        }}
                    >
                      {_language.page1.in}
                    </h4>
                </button>
              </div>
            </div>

            <div className='block' style={{
              height : "6.5%"
            }}/>

            <Slider
              handleSliderChange={handleSliderChange}
              handleSliderChangeIN={handleSliderChangeIN}
              sliderValues={sliderValues}
              renderValue={renderValue}
              unit={unit}
              type="height"
              content = {_language.page1.height}
              _language = {_language}
              measurement = {measurement}
            />
            <div className='block' style={{
              height : "6.5%"
            }}/>

            <div className='block_mobile' style={{
              height : "2%"
            }}/>

            <Slider
              handleSliderChange={handleSliderChange}
              handleSliderChangeIN={handleSliderChangeIN}
              sliderValues={sliderValues}
              renderValue={renderValue}
              unit={unit}
              type="waist"
              content = {_language.page1.waist}
              _language = {_language}
              measurement = {measurement}
            />
            <div className='block' style={{
              height : "6.5%"
            }}/>

            <div className='block_mobile' style={{
              height : "2%"
            }}/>

          <Slider
              handleSliderChange={handleSliderChange}
              handleSliderChangeIN={handleSliderChangeIN}
              sliderValues={sliderValues}
              renderValue={renderValue}
              unit={unit}
              type="hip"
              content = {_language.page1.hip}
              _language = {_language}
              measurement = {measurement}
            />
    </>
  )
}

const C2 = ({size , _language , sliderValues})=>{
  const {recordGameData} = useSuperfan();
  recordGameData("sizetype" , {
    fitted : size.fitted,
      baggy : size.baggy,
  })

  let timeInsec = new Date().getTime();
  let seachParams = new URLSearchParams(window.location.search);
  let product = seachParams.get('product');
  
  recordGameData(timeInsec , {
    product : product,
    height : sliderValues.height,
    waist : sliderValues.waist,
    hip : sliderValues.hip,
    fitted : size.fitted,
    baggy : size.baggy,
  })
return (
    <>
      <h4 className='' style={{
        textTransform : "uppercase",
        fontFamily : "Klein-65Medium",
        fontWeight : "500",
      }}>{_language.page1.recommendedsize}</h4>
      <div className='block' style={{
            height : "15%"
      }}/>


    <section
        style={{
          display : "flex",
          justifyContent : "center",
          alignItems : "center",
          width : "100%",
        }}
      >
        <div>
          <Button2
            id = "fitted"
          >
            <span style={{
                fontFamily : "Klein-55Regular",
                textTransform : "uppercase",
                fontWeight : "400",
              }}> {"W" + size.fitted}</span>

          </Button2>

          <div className='block' style={{
                height : "0.5rem"
          }}/>

          <p>{_language.page1.Fitted}</p>


        </div>
        
        <div className='block' style={{
          width : "5%"
        }}/>

        <div>

          <Button2
            id = "baggy"
          >
          <span style={{
              fontFamily : "Klein-55Regular",
              textTransform : "uppercase",
              fontWeight : "400",
            }}> {"W"  + size.baggy}</span>
          </Button2>

          <div className='block' style={{
                height : "0.5rem"
          }}/>
                    <p>{_language.page1.baggy}</p>

        </div>

      </section>

    </>
  )
}
export default Index;

const PopUpSelelct = ()=>{
  let keys = Object.keys(Language_);
  return (
    <div className='popUpSelect'
      style={{
        position : "absolute",
        left : "50%",
        top : "50%",
        width : "70%",
        height : "70%",
        transform : "translate(-50%,-50%)",
        backgroundColor : "rgba(255,255,255)",
        border : "1px solid #000",
      }}
    >
      <div style={{height :  "15%"}}/>

      {
        keys.map((item, key) => {
          return (
            <>
            <div
              key={key}
              style={{
                width : "100%",
                height : "10%",
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                }}
              >
                <h2>{item.toUpperCase()}</h2>
              </div>
              <div style={{height :  "3%"}}/>
            </>
          )
        })
      }
    </div>
  )
}

// https://fitfinder.conten.tech?type=asiamen&product=90S%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiamen&product=AR-%20AUTHENTIC%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiamen&product=AR-%20AUTHENTIC%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiamen&product=AR-%20BODY&language=en
// https://fitfinder.conten.tech?type=asiamen&product=AR-%20BODY%20TAPER&language=en


// // women asia
// https://fitfinder.conten.tech?type=asiawomen&product=90S%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiawomen&product=90S%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiawomen&product=A-%20LOW%20RISE%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiawomen&product=AR-LOW%20RISE%20STRAIGHT&language=en
// https://fitfinder.conten.tech?type=asiawomen&product=AR-%20LOW%20RISE%20BAGGY%20CARGO&language=en
// https://fitfinder.conten.tech?type=asiawomen&product=AR-HR%20FLARE&language=en


// // women us
// https://fitfinder.conten.tech?type=uswomen&product=ULTRA%20HIGH%20WIDE%20LEG%20PACIFICO%20JEAN&language=en
// https://fitfinder.conten.tech?type=uswomen&product=DENIM%20MINI%20SKIRT%20(CMFRT%20STRTCH)%20-%20BLUE%20TOPAZ%20X&language=en
// https://fitfinder.conten.tech?type=uswomen&product=SCULPT%20BODYCON%20DRESS%20-%20CK%20CLASSIC%20RINSE&language=en