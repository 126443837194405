export const language = {
  en: {
    type: "en",
    cm : "cm",
    in : "in",
    page1: {
      title: "Size Finder",
      content: "Use the slide tool to find your perfect size.",
      product: "Product:",
      cm: "CM",
      in: "IN",
      height: "Height",
      waist: "waist",
      hip: "Hip",
      recommendedsize: "Recommended size",
      howtomeasure: "How to Measure",
      findmysize: "Find My Size",
      baggy : "Baggy",
      Fitted : "Fitted",
      tryagain : "Try again",
    },
    page2 : {
      shoulder : "Shoulder",
      fromshouldertip : "From shoulder tip to shoulder tip.",
      chest : "Chest",
      thefirstpart : "The fullest part of your bust (keep<br/> measuring tape horizontal).",
      waist : "Waist",
      theslimmestpart : "The slimmest part of your natural<br/> waistline - above your navel below <br/>your ribcage.",
      hips : "Hips",
      thefullestpart : "The fullest part of your hips at the top of<br/> your legs - approximately nine inches<br/> below your natural waistline.",
    }
  },
  hk: {
    type: "cn",
    cm : "厘米",
    in : "英寸",
    page1: {
      title: "尺碼指南", // 尺码 (chǐ mǎ) - size
      content: "向左右滑動，找出適合你的尺碼",
      product: "Product:",  // 产品 (chǎn pǐn) - product
      cm: "cm",  // 厘米 (lí mi) - centimeter
      in: "in",  // 英寸 (yīng cùn) - inch
      height: "身高",  // 身高 (shēn gāo) - height
      waist: "腰围",  // 体重 (tǐ zhòng) - weight
      hip: "臀围",  // 臀围 (tún wéi) - hip
      recommendedsize: "建議尺碼",  // 推荐 (tuī jiàn) - recommended
      howtomeasure: "如何量度",  // 如何 (rú hé) - how, 测量 (cé liang) - measure
      findmysize: "找出適合我的尺碼",  // 查找 (cháo zhão) - find, 我的 (wǒ de) - my, 尺码 (chǐ mǎ) - size
      baggy : "寬鬆",
      Fitted : "修身",
      tryagain : "再試一次",
    },
    page2 : {
      shoulder : "肩宽",  // 肩宽 (jiān kuān) - shoulder width
      fromshouldertip : "由左肩點到右肩點。",
      chest : "胸圍",
      thefirstpart : "胸圍最豐滿的部分（保持捲尺水平）。",
      waist : "腰圍",
      theslimmestpart : "在肚臍上方和胸下方，<br/>在自然腰圍的最細圍位置量度一次。",
      hips : "臀圍",
      thefullestpart : "在臀部最豐滿的位置量度一圈，<br/>通常比自然腰圍低約 9 吋。",
    }
  },

  tw: {
    type: "cn",
    cm : "公分",
    in : "英吋",
    page1: {
      title: "尺碼指南", // 尺码 (chǐ mǎ) - size
      content: "向左右滑動，找出適合您的尺碼",
      product: "Product:",  // 产品 (chǎn pǐn) - product
      cm: "cm",  // 厘米 (lí mi) - centimeter
      in: "in",  // 英寸 (yīng cùn) - inch
      height: "身高",  // 身高 (shēn gāo) - height
      waist: "腰圍",  // 体重 (tǐ zhòng) - weight
      hip: "臀圍",  // 臀围 (tún wéi) - hip
      recommendedsize: "推荐尺码",  // 推荐 (tuī jiàn) - recommended
      howtomeasure: "如何測量",  // 如何 (rú hé) - how, 测量 (cé liang) - measure
      findmysize: "找出適合我的尺碼",  // 查找 (cháo zhão) - find, 我的 (wǒ de) - my, 尺码 (chǐ mǎ) - size
      baggy : "寬鬆",
      Fitted : "合身",
      tryagain : "再試一遍",
    },
    page2 : {
      shoulder : "肩宽",  // 肩宽 (jiān kuān) - shoulder width
      fromshouldertip : "由左肩點到右肩點。",
      chest : "胸圍",
      thefirstpart : "胸圍最豐滿的部分（保持捲尺水平）。",
      waist : "腰圍",
      theslimmestpart : "在肚臍上方和胸下方，<br/>在自然腰圍的最細圍位置量度一次。",
      hips : "臀圍",
      thefullestpart : "在臀部最豐滿的位置量度一圈，<br/>通常比自然腰圍低約 9 吋。",
    }
  },

  kr: {
    type: "kr",
    cm : "cm",
    in : "in",
    page1: {
      title: "사이즈 찾기", // 尺码 (chǐ mǎ) - size
      content: "슬라이더 도구를 사용하여 꼭<br/> 맞는 사이즈를 찾으세요!",
      product: "Product:",  // 产品 (chǎn pǐn) - product
      cm: "cm",  // 厘米 (lí mi) - centimeter
      in: "in",  // 英寸 (yīng cùn) - inch
      height: "키",  // 身高 (shēn gāo) - height
      waist: "허리",  // 体重 (tǐ zhòng) - weight
      hip: "엉덩이",  // 臀围 (tún wéi) - hip
      recommendedsize: "권장 사이즈",  // 推荐 (tuī jiàn) - recommended
      howtomeasure: "측정 방법",  // 如何 (rú hé) - how, 测量 (cé liang) - measure
      findmysize: "내 사이즈 찾기",  // 查找 (cháo zhão) - find, 我的 (wǒ de) - my, 尺码 (chǐ mǎ) - size
      baggy : "여유있는 핏",
      Fitted : "잘 맞는 핏",
      tryagain : "다시 보기",
    },
    page2 : {
      shoulder : "어깨",  // 肩宽 (jiān kuān) - shoulder width
      fromshouldertip : "어깨 끝에서 어깨 끝까지",
      chest : "가슴",
      thefirstpart : "가슴의 가장 두꺼운 부분",
      waist : "허리",
      theslimmestpart : "허리선에서 가장 얇은 부분 – 배<br/>꼽 위와 흉곽 아래",
      hips : "엉덩이",
      thefullestpart : "엉덩이의 가장 넓은 부분 – 허리<br/>에서 약 20cm 아래",
    }
  },

  jp: {
    type: "jp",
    cm : "cm",
    in : "in",
    page1: {
      title: "サイズファインダー", // 尺码 (chǐ mǎ) - size
      content: "スライダーツールを使ってぴった<br/>りのサイズを見つけよう",
      product: "Product:",  // 产品 (chǎn pǐn) - product
      cm: "cm",  // 厘米 (lí mi) - centimeter
      in: "in",  // 英寸 (yīng cùn) - inch
      height: " 総丈",  // 身高 (shēn gāo) - height
      waist: "ウエスト",  // 体重 (tǐ zhòng) - weight
      hip: "ヒップ",  // 臀围 (tún wéi) - hip
      recommendedsize: "お勧めのサイズ",  // 推荐 (tuī jiàn) - recommended
      howtomeasure: "測り方",  // 如何 (rú hé) - how, 测量 (cé liang) - measure
      findmysize: "自分のサイズを探す",  // 查找 (cháo zhão) - find, 我的 (wǒ de) - my, 尺码 (chǐ mǎ) - size
      baggy : "ゆったり",
      Fitted : "ぴったり",
      tryagain : "やり直す",
    },
    page2 : {
      shoulder : "肩幅",  // 肩宽 (jiān kuān) - shoulder width
      fromshouldertip : "肩先から肩先まで。",
      chest : "胸囲",
      thefirstpart : "胸の最も高い部分（メジャー<br/>を水平に保ちましょう）。",
      waist : "ウエスト",
      theslimmestpart : "自然なウエストラインの最も細い部分<br/>（へその上、胸郭の下）。",
      hips : "ヒップ",
      thefullestpart : "ヒップの最も高い部分（脚の一番上、<br/>自然なウエストラインから約9<br/>センチ下の部分）。",
    }
  },
};